@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,500;0,600;0,700;1,500;1,600&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.rotate-y-180 {
  transform: rotateY(180deg);
}

.rotate-y-360 {
  transform: rotateY(360deg);
}


.rotate-y-0 {
  transform: rotateY(0deg);
}

.rotate-y-45 {
  transform: rotateY(45deg);
}
.rotate-y--45 {
  transform: rotateY(-45deg);
}



.perspective-1000 {
  perspective: 1000px;
}